<script>
import { SuawCarousel } from "@/components";
import * as db from "../data.js";
import { formatDistanceToNow } from "date-fns";

export default {
  name: "AuthorPosts",
  components: {
    SuawCarousel
  },
  props: {
    authorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      authors: db.authors(),
      articlesAll: db.articles()
    };
  },
  computed: {
    author() {
      return this.authors.filter(a => a.id === this.authorId)[0];
    },
    articles() {
      const currentRoute = this.$route.path;
      // Filter articles to exclude the current article
      const filteredArticles = this.articlesAll.filter(article => this.author.articles.includes(article.id)).filter(article => article.route !== currentRoute);
      return filteredArticles;
    }
  },
  methods: {
    getRelativeTime(posted) {
      const result = formatDistanceToNow(new Date(posted), { addSuffix: true });
      return result;
    }
  }
};
</script>

<template>
  <SuawCarousel :title="`More Posts by ${author.name}`" :cards="articles" />
</template>
